import { store } from '../index'
import { notify } from 'reapop'
import configureStore from '../configureStore'
// Intercept and refresh expired tokens for multiple requests (same implementation but with some abstractions)
// 
// HOW TO USE:
// import applyAppTokenRefreshInterceptor from 'axios.refresh_token.2.js';
// import axios from 'axios';
// ...
// applyAppTokenRefreshInterceptor(axios); // register the interceptor with all axios instance
// ...
// - Alternatively:
// const apiClient = axios.create({baseUrl: 'example.com/api'});
// applyAppTokenRefreshInterceptor(apiClient); // register the interceptor with one specific axios instance
// ...
// - With custom options:
// applyAppTokenRefreshInterceptor(apiClient, {
//      shouldIntercept: (error) => {
//          return error.response.data.errorCode === 'EXPIRED_ACCESS_TOKEN';
//      }
// ); // register the interceptor with one specific axios instance
//
// PS: You may need to figure out some minor things yourself as this is just a proof of concept and not a tutorial.
// Forgive me in advance

const shouldIntercept = (error) => {
    try {
        if(!error.response)
          return true
        const status = error && error.response ? error.response.status : 0
        return status === 401 || status === 429
    } catch (e) {
        return false;
    }
};

const setTokenData = (tokenData = {}, axiosClient) => {
    // If necessary: save to storage
    //   tokenData's content includes data from handleTokenRefresh(): {
    //     accessToken: data.auth_token,
    //     refreshToken: data.refresh_token,
    //     expiresAt: data.expires_in,
    // };
};

const handleTokenRefresh = () => {
    /* const refreshToken = window.localStorage.getItem('refreshToken');
    return new Promise((resolve, reject) => {
        axios.post('http://localhost:8000/auth/refresh', { refreshToken })
            .then(({data}) => {
                const tokenData = {
                    accessToken: data.auth_token,
                    refreshToken: data.refresh_token,
                    expiresAt: data.expires_at,
                };
                resolve(tokenData);
            })
            .catch((err) => {
                reject(err);
            })
    }); */
};

const attachTokenToRequest = (request, token) => {
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`
    }
};

const setupResponseInterceptor = (axiosClient, customOptions = {}) => {
  let isRefreshing = false;
  let failedQueue = [];

  const options = {
      attachTokenToRequest,
      handleTokenRefresh,
      setTokenData,
      shouldIntercept,
      ...customOptions,
  };
  const processQueue = (error, token = null) => {
      failedQueue.forEach(prom => {
          if (error) {
              prom.reject(error);
          } else {
              prom.resolve(token);
          }
      });

      failedQueue = [];
  };

  const interceptor = async (error) => {
    const originalRequest = error.config;
    const { dispatch } = store
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    } else if (error && !error.response) {
      dispatch(notify({
        title: 'Processing your request...',
        message: "Due to a high volume of requests we'll need a moment to load this page. Please wait for a few seconds...",
        status: 'warning',
        position: 'tr',
        dismissAfter: '25000',
        dismissible: true,
        id: 'woah'
      }))
      const wait = () => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(axiosClient.request(originalRequest));
          }, 25000);
        });
      }
      return await wait()
    }


    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }


    if (isRefreshing) {
      return new Promise(function(resolve, reject) {
        failedQueue.push({ resolve, reject })
      }).then(token => {
        originalRequest._queued = true;
        options.attachTokenToRequest(originalRequest, token);
        return axiosClient.request(originalRequest);
      }).catch(err => {
        return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
      })
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh.call(options.handleTokenRefresh)
        .then((tokenData) => {
          options.setTokenData(tokenData, axiosClient);
          options.attachTokenToRequest(originalRequest, tokenData.accessToken);
          processQueue(null, tokenData.accessToken);
          resolve(axiosClient.request(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        })
    });
  };

  axiosClient.interceptors.response.use(response => response, interceptor);
};


export const setUpAuthInterceptor = (axiosClient) => {
  const interceptor = async (error) => {
    const { dispatch } = store
    const originalRequest = error.config;
    console.log(JSON.stringify(error))
    if (!error.response) {
      dispatch(notify({
        title: 'Processing your request...',
        message: "Due to a high volume of requests we'll need a moment to load this page. Please wait for a few seconds...",
        status: 'warning',
        position: 'tr',
        dismissAfter: '25000',
        id: 'woah',
        dismissible: true,
      }))
      const wait = () => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(axiosClient.request(originalRequest));
          }, 25000);
        });
      }
      return await wait()
    }

    return Promise.reject(error);


  };
  axiosClient.interceptors.response.use(response => response, interceptor);

}

export function setupInterceptorsTo(axiosClient, responseOptions, getToken) {
  //setupRequestInterceptor(axiosClient, getToken)
  setupResponseInterceptor(axiosClient, responseOptions)
  //console.log("calling setup", axiosClient.defaults)
  return axiosClient
}